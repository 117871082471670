import { getUserTask, getUserCollect, createTaskByPoetryId } from '@/api/layout/aside.js'
import { getCharacters } from '@/api/layout/index.js'
const state = {
    historyList: [],
    collectList: [],
    // userId: '',
    // body: ''
    poetryId: '',

}
const mutations = {
    GETGOODINFO(state, historyList) {
        state.historyList = historyList
    },
    GETCOLLECT(state, collectList) {
        state.collectList = collectList
    },
    // 古诗点击加载
    GETCREATETASK(state, poetryId, userId) {
        state.poetryId = poetryId
        state.userId = userId
    },

}

const actions = {
    //  按userid查询历史
    async getGoodInfo({ commit }) {
        let result = await getUserTask();
        if (result.code == 200) {
            commit('GETGOODINFO', result.entityList)
        }
    },
    //  按userid查询收藏
    async getCollect({ commit }) {
        let result = await getUserCollect();
        if (result.code == 200) {
            commit('GETCOLLECT', result.entityList)
        }
    },

    //  古诗点击加载
    async createTaskByPoetryId({ commit }, poetryId, userId) {
        let result = await createTaskByPoetryId(poetryId, userId,);
        if (result.code == 200) {
            commit('GETCREATETASK', poetryId, userId)
        }
    },




}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
