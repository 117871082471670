const Ly_URL = process.env.VUE_APP_CURRENTMODE === 'development' ? '/api/poetry' : process.env.VUE_APP_LY_HOST + '/api/poetry';
const token = localStorage.getItem('token')
/**
 * 按userid查询历史
 * @param {object} body 
 * @returns 
 */
export function getUserTask() {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token')
            const res = await fetch(`${Ly_URL}/getHistory`,
                {
                    method: "POST",
                    headers: {
                        // "Content-Type": "application/json",
                        "Authorization": token
                    },
                })
            if (res.status == 200) {
                const json = await res.json();
                resolve(json);
            } else {
                const json = await res.json();
                reject(json.error);
            }
        } catch (error) {
            reject(error);
        }
    })
}


/**
 * 按userid查询收藏
 * @param {object} body 
 * @returns 
 */
export function getUserCollect() {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token')
            const res = await fetch(`${Ly_URL}/getCollect`,
                {
                    method: "POST",
                    headers: {
                        // "Content-Type": "application/json",
                        "Authorization": token
                    },
                })
            if (res.status == 200) {
                const json = await res.json();
                resolve(json);
            } else {
                const json = await res.json();
                reject(json.error);
            }
        } catch (error) {
            reject(error);
        }
    })
}

/**
 * 古诗点击加载
 * @param {object} body 
 * @returns 
 */
export function createTaskByPoetryId(poetryId) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token')
            const res = await fetch(`${Ly_URL}/createTaskByPoetry?poetryId=${poetryId}`,
                {
                    method: "POST",
                    headers: {
                        // "Content-Type": "application/json",
                        "Authorization": token
                    },
                })
            if (res.status == 200) {
                const json = await res.json();
                resolve(json);
            } else {
                const json = await res.json();
                reject(json.error);
            }
        } catch (error) {
            reject(error);
        }
    })
}

/**
 * 点击查询历史
 * @param {object} body 
 * @returns 
 */
export function getTask(recordId) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token')
            const res = await fetch(`${Ly_URL}/getHistoryDetails?recordId=${recordId}`,
                {
                    method: "POST",
                    headers: {
                        // "Content-Type": "application/json",
                        "Authorization": token
                    },
                })
            if (res.status == 200) {
                const json = await res.json();
                resolve(json);
            } else {
                const json = await res.json();
                reject(json.error);
            }
        } catch (error) {
            reject(error);
        }
    })
}

// 删除历史
/**
 * 通过文本获取图片 并创建任务
 * @param {object} body 
 * @returns 
 */
export function deleteTaskByTaskId(recordId) {
    // body = JSON.stringify(body);

    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token')
            const res = await fetch(`${Ly_URL}/cancelCollectRecord?recordId=${recordId}`,
                {
                    method: "POST",
                    headers: {
                        // "Content-Type": "application/json",
                        "Authorization": token,
                    },

                    body: {}
                })
            if (res.status == 200) {
                const json = await res.json();
                resolve(json);
            } else {
                const json = await res.json();
                reject(json.error);
            }
        } catch (error) {
            reject(error);
        }
    })
}