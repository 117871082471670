import Vue from 'vue'
import Vuex from 'vuex'
import aside from './modules/aside.js'

import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        aside
    },
    getters
})

export default store