<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
* {
  user-select: none;
}

#app {
  /* position: relative; */
  height: 100vh;
}
</style>
