import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/index';
import '@/assets/css/reset.css'
import axios from 'axios'
Vue.prototype.$axios = axios

// 长时间页面不操作，自动退出登录清除token
// import Astrict from '@/utils/astrict.js'
// Vue.use(Astrict)

import store from './store'
function start() {
    // 监听 window.unload 事件  
    window.addEventListener('beforeunload', () => {
        localStorage.clear();
    });
}

// function start() {
//     function handleBeforeUnload() {
//         event.preventDefault()
//         event.returnValue = ''
//         localStorage.setItem('browserClosed', "1")
//         localStorage.setItem('browserClosedTime', Date.parse(new Date()));
//     }
//     window.addEventListener('beforeunload', handleBeforeUnload)


//     const browserClosed = localStorage.getItem('browserClosed');
//     const browserClosedTime = localStorage.getItem('browserClosedTime');
//     if (browserClosed != undefined || browserClosed != null) {
//         if (browserClosedTime && Date.parse(new Date()) - browserClosedTime > 3000) {
//             localStorage.clear();
//         } else {
//         }
//     }
// }

start();
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
    //全局事件总线 第一步
    beforeCreate() {
        Vue.prototype.$bus = this;
    },

}).$mount('#app')