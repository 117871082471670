const Ly_URL = process.env.VUE_APP_CURRENTMODE === 'development' ? '/api/poetry' : process.env.VUE_APP_LY_HOST + '/api/poetry';
const Ly_LOGIN = process.env.VUE_APP_CURRENTMODE === 'development' ? '/api/poetry' : process.env.VUE_APP_LY_HOST + '/api/poetry';
import { Message } from 'element-ui'
/**
 * 通过语音获取文本 并创建任务
 * @param {object} body 
 * @returns 
 */
export function createTask(body, userId) {
    // body = JSON.stringify(body);

    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token')
            const res = await fetch(`${Ly_URL}/ancientPoetry/createImages?userId=${userId}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": token
                    },
                    body: body
                })
            if (res.status == 200) {
                const json = await res.json();
                resolve(json);
            } else {
                const json = await res.json();
                reject(json.error);
            }
        } catch (error) {
            reject(error);
        }
    })
}

/**
 * 通过语音获取文本 并创建任务
 * @param {object} body 
 * @returns 
 */
export function getCharacters(body, vueInstance) {
    // body = JSON.stringify(body);
    // 创建一个 AbortController 实例
    const controller = new AbortController();

    // 将 controller.signal 关联到请求的 signal
    const signal = controller.signal;

    // 设置一个定时器，在15秒后调用 controller.abort() 来取消请求
    const timeoutId = setTimeout(() => {
        controller.abort();
    }, 15000); // 15秒后取消请求

    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token')
            const res = await fetch(`${Ly_URL}/createTaskByVoice`,
                {
                    method: "POST",
                    headers: {
                        // "Content-Type": "multipart/form-data",
                        "Authorization": token
                    },
                    body: body,
                    signal: signal, // 关联 AbortController 的 signal
                })
            clearTimeout(timeoutId); // 取消定时器
            if (res.status == 200) {
                const json = await res.json();
                resolve(json);
            } else {
                const json = await res.json();
                reject(json.error);
            }
        } catch (error) {
            Message({
                message: '未识别到相应诗句!',
                type: "warning",
            });
            // 识别错误
            let audio = new Audio();
            audio.src = require("@/assets/mp3/notSupported.mp3");
            audio.play();
            vueInstance.$emit("change", {
                type: "login-success",
            });
            vueInstance.loading = false;
        }
    })
}


/**
 *  根据userTask id 来生成图片
 * @param {object} body 
 * @returns 
 */
export function generateImagesByTaskId(userTaskId) {
    // body = JSON.stringify(body);

    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token')
            const res = await fetch(`${Ly_URL}/generateImagesByUserTaskId?userTaskId=${userTaskId}`,
                {
                    method: "POST",
                    headers: {
                        // "Content-Type": "multipart/form-data",
                        "Authorization": token
                    },
                    body: {}
                })
            if (res.status == 200) {
                const json = await res.json();
                resolve(json);
            } else {
                const json = await res.json();
                reject(json.error);
            }
        } catch (error) {
            reject(error);
        }
    })
}

/**
 *  根据userTask id 来生成图片 一张图
 * @param {object} body 
 * @returns 
 */
export function generateImagesBySubTaskId(subTaskId) {
    // body = JSON.stringify(body);

    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token')
            const res = await fetch(`${Ly_URL}/generateImagesBySubTaskId?subTaskId=${subTaskId}`,
                {
                    method: "POST",
                    headers: {
                        // "Content-Type": "multipart/form-data",
                        "Authorization": token
                    },
                    body: {}
                })
            if (res.status == 200) {
                const json = await res.json();
                resolve(json);
            } else {
                const json = await res.json();
                reject(json.error);
            }
        } catch (error) {
            reject(error);
        }
    })
}
/**
 * 按userid查询历史
 * @param {object} body 
 * @returns 
 */
export function createImages(body, userId) {
    // body = JSON.stringify(body);

    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token')
            const res = await fetch(`${Ly_URL}/ancientPoetry/createImages?userId=${userId}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": token
                    },
                    body: body
                })
            if (res.status == 200) {
                const json = await res.json();
                resolve(json);
            } else {
                const json = await res.json();
                reject(json.error);
            }
        } catch (error) {
            reject(error);
        }
    })
}

/**
 * 登录
 * @param {object} body 
 * @returns 
 */
export function login(body) {

    return new Promise(async (resolve, reject) => {
        try {
            const res = await fetch(`${Ly_LOGIN}/auth/login`,
                {
                    method: "POST",
                    headers: {
                        // "Content-Type": "multipart/form-data",
                    },
                    body: body
                })
            if (res.status == 200) {
                const json = await res.json();
                resolve(json);
            } else {
                const json = await res.json();
                reject(json.error);
            }
        } catch (error) {
            reject(error);
        }
    })
}
/**
 * 注册
 * @param {object} body 
 * @returns 
 */
export function signin(body, nickname) {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await fetch(`${Ly_LOGIN}/auth/register?nickname=${nickname}`,
                {
                    method: "POST",
                    headers: {
                        // "Content-Type": "multipart/form-data",
                    },
                    body: body
                })
            if (res.status == 200) {
                const json = await res.json();
                resolve(json);
            } else {
                const json = await res.json();
                reject(json.error);
            }
        } catch (error) {
            reject(error);
        }
    })
}

/**
 * 获取模糊图
 * @param {object} body 
 * @returns 
 */
export function getProgress(subTaskId) {
    // body = JSON.stringify(body);

    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token')
            const res = await fetch(`${Ly_URL}/getProgress?subTaskId=${subTaskId}`,
                {
                    method: "POST",
                    headers: {
                        // "Content-Type": "multipart/form-data",
                        "Authorization": token
                    },
                    body: {}
                })
            if (res.status == 200) {
                const json = await res.json();
                resolve(json);
            } else {
                const json = await res.json();
                reject(json.error);
            }
        } catch (error) {
            reject(error);
        }
    })
}

/**
 * 刷新
 * @param {object} body 
 * @returns 
 */
export function createTaskByTaskId(taskId) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token')
            const res = await fetch(`${Ly_URL}/ancientPoetry/createTaskByTaskId?taskId=${taskId}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": token
                    },
                    body: {}
                })
            if (res.status == 200) {
                const json = await res.json();
                resolve(json);
            } else {
                const json = await res.json();
                reject(json.error);
            }
        } catch (error) {
            reject(error);
        }
    })
}



/**
 * 出图前切换模型
 * @param {object} body 
 * @returns 
 */
export function generateImagesBySubTaskIdTry(subTaskId) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token')
            const res = await fetch(`${Ly_URL}/generateImagesBySubTaskIdTry?subTaskId=${subTaskId}`,
                {
                    method: "POST",
                    headers: {
                        "Authorization": token
                    },
                    body: {}
                })
            if (res.status == 200) {
                const json = await res.json();
                resolve(json);
            } else {
                const json = await res.json();
                reject(json.error);
            }
        } catch (error) {
            reject(error);
        }
    })
}

/**
 * 刷新终止以前的生成任务
 * @param {object} body 
 * @returns 
 */
export function interrupt() {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token')
            const res = await fetch(`${Ly_URL}/interrupt`,
                {
                    method: "POST",
                    headers: {
                        "Authorization": token
                    },
                    body: {}
                })
            if (res.status == 200) {
                const json = await res.json();
                resolve(json);
            } else {
                const json = await res.json();
                reject(json.error);
            }
        } catch (error) {
            reject(error);
        }
    })
}

/**
 * 文件上传
 * @param {object} body 
 * @returns 
 */
export function uploadFile(data) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token')
            const res = await fetch(`${Ly_URL}/uploadFile`,
                {
                    method: "POST",
                    headers: {
                        "Authorization": token,
                    },
                    body: data
                })
            if (res.status == 200) {
                const json = await res.json();
                resolve(json);
            } else {
                const json = await res.json();
                reject(json.error);
            }
        } catch (error) {
            reject(error);
        }
    })
}

