import Vue from "vue";
import VueRouter from 'vue-router';

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        component: () => import('@/view/Layout/index.vue'),
        meta: {
            show: true
        }
    },
    // 注册
    {
        path: "/signin",
        component: () => import('@/view/signIn/index.vue'),
        meta: { show: true }
    },

    // -----------------------------------------------------------------

    {
        path: "/ProgressBarDirective",
        component: () => import('@/components/ProgressBarDirective/index.vue'),
        meta: { show: true }
    },
    // {
    //     path: "/sd",
    //     component: () => import('@/view/SdTest/index.vue'),
    //     meta: { show: true }
    // },
    {
        path: "/skeleton",
        component: () => import('@/view/skeleton/skeleton_main.vue'),
        meta: { show: true }
    },

    {
        path: "/face",
        component: () => import('@/view/face/index.vue'),
        meta: { show: true }
    },


]

//3.创建VueRouter的实例
const router = new VueRouter({
    mode: 'hash',
    routes
})
//5.导入路由实例
export default router
